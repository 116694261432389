import {SpeakerWaveIcon, SpeakerXMarkIcon} from '@heroicons/react/20/solid';
import cx from 'classnames';
import {useState} from 'react';

import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {PlayerControlsIcon} from '@/components/player-v2/PlayerControlsIcon';
import {usePlayerVolume} from '@/components/player-v2/PlayerVolumeProvider';

const TRANSITION_CLASS_NAME = 'transition-all duration-200';

export const VolumeControl: React.FC = () => {
  const {isMuted, toggleMute} = usePlayer();
  const {volume, setVolume} = usePlayerVolume();
  const [isVolumeSliderVisible, setIsVolumeSliderVisible] = useState(false);

  return (
    <div
      className={cx(TRANSITION_CLASS_NAME, 'rounded-full flex', {
        'bg-grayscale-600': isVolumeSliderVisible,
      })}
      onMouseEnter={() => setIsVolumeSliderVisible(true)}
      onMouseLeave={() => setIsVolumeSliderVisible(false)}
    >
      <div
        className={cx('flex items-center pl-2', TRANSITION_CLASS_NAME, {
          'opacity-0': !isVolumeSliderVisible,
          'opacity-100': isVolumeSliderVisible,
        })}
      >
        <input
          className="w-full"
          type="range"
          onChange={(e) => setVolume(Number(e.target.value))}
          value={isMuted ? 0 : volume}
          max={1}
          step={0.01}
        />
      </div>

      <PlayerControlsIcon
        onClick={toggleMute}
        icon={isMuted ? <SpeakerXMarkIcon /> : <SpeakerWaveIcon />}
      />
    </div>
  );
};
