import React from 'react';
import cx from 'classnames';

interface PlayerControlsIconProp {
  onClick: () => void;
  icon: React.ReactElement;
  enabled?: boolean;
}

export const PlayerControlsIcon: React.FC<PlayerControlsIconProp> = ({
  icon,
  onClick,
  enabled = true,
}) => {
  const clonedIcon = React.cloneElement(icon, {
    className: cx(icon.props.className, 'h-8 w-8'),
  });

  return (
    <button className="px-2 py-1" onClick={onClick} disabled={!enabled}>
      {clonedIcon}
    </button>
  );
};
