import {RefObject, useCallback, useEffect} from 'react';
import ReactPlayer from 'react-player';

import {usePlayer} from '@/components/player-v2/PlayerProvider';
import screenfull from '@/utils/screenfull';

/**
 * Adds keyboard shortcuts to the document for controller the player.
 */
export const usePlayerKeyboardShortcuts = (
  isActive: boolean,
  playerRef: RefObject<ReactPlayer>
) => {
  const {hasControls, togglePlay, toggleMute} = usePlayer();

  const toggleFullScreen = () => {
    const videoIsFullScreen = document.fullscreenElement;
    if (videoIsFullScreen) {
      screenfull.exit();
    } else {
      screenfull.request();
    }
  };

  const keyUpHandler = useCallback(
    (event: DocumentEventMap['keyup']) => {
      const {key} = event;

      // Don't interfere with user typing in inputs
      if (document.activeElement?.tagName === 'INPUT') {
        return;
      }

      // If the player is not selected, don't handle keyboard shortcuts
      if (!isActive) {
        return;
      }

      switch (key) {
        case ' ': {
          return togglePlay();
        }
        case 'm': {
          return toggleMute();
        }
        case 'f': {
          return toggleFullScreen();
        }
      }
    },
    [toggleFullScreen, toggleMute, togglePlay, playerRef]
  );

  useEffect(() => {
    if (hasControls) {
      document.addEventListener('keyup', keyUpHandler);
    } else {
      document.removeEventListener('keyup', keyUpHandler);
    }

    return () => document.removeEventListener('keyup', keyUpHandler);
  }, [hasControls, keyUpHandler]);
};
