import {
  PlayIcon,
  PauseIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/20/solid';
import cx from 'classnames';

import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {ProgressBar} from '@/components/player-v2/ProgressBar';
import {PlayerControlsIcon} from '@/components/player-v2/PlayerControlsIcon';
import {VolumeControl} from '@/components/player-v2/VolumeControl';
import screenfull from '@/utils/screenfull';
import {PlayerControlsTimestamp} from '@/components/player-v2/PlayerControlsTimestamp';

interface PlayerControlsProps {
  className?: string;
}

export const PlayerControls: React.FC<PlayerControlsProps> = ({className}) => {
  const {disabledControls, duration, hasControls, isPlaying, togglePlay} =
    usePlayer();
  const toggleFullScreen = () => {
    const videoIsFullScreen = document.fullscreenElement;
    if (videoIsFullScreen) {
      screenfull.exit();
    } else {
      screenfull.request();
    }
  };

  if (!hasControls) {
    return null;
  }

  if (!hasControls) {
    return null;
  }

  return (
    <div className="z-10 flex flex-col">
      <div
        className={cx(
          'px-3 py-4 bg-gradient-to-t from-black/75 to-black/0',
          className
        )}
      >
        {!hasControls || disabledControls.includes('progressBar') ? null : (
          <ProgressBar className="mb-2" />
        )}

        <div className="flex justify-between">
          <div className="flex items-center">
            <PlayerControlsIcon
              onClick={togglePlay}
              enabled={hasControls}
              icon={!isPlaying ? <PlayIcon /> : <PauseIcon />}
            />
            {duration ? <PlayerControlsTimestamp duration={duration} /> : null}
          </div>

          <div className="flex">
            <VolumeControl />

            {!disabledControls.includes('fullscreen') ? (
              <PlayerControlsIcon
                enabled={hasControls}
                onClick={toggleFullScreen}
                icon={<ArrowsPointingOutIcon />}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
