import {useInternalPlayerConfig} from '@/components/player-v2/PlayerProvider';
import {ArrowLeftIcon} from '@heroicons/react/20/solid';
import cx from 'classnames';

interface PlayerOverlayTopBarProps {
  className?: string;
}

export const PlayerOverlayTopBar: React.FC<PlayerOverlayTopBarProps> = ({
  className,
}) => {
  const {onBackButtonClick, backButtonBehavior} = useInternalPlayerConfig();

  if (backButtonBehavior?.type === 'none') {
    return null;
  }

  return (
    <div
      className={cx('p-5 bg-gradient-to-b from-black/75 to-black/0', className)}
    >
      <button onClick={onBackButtonClick}>
        <ArrowLeftIcon title="Go back" className="w-8 h-8 sm:w-12 sm:h-12" />
      </button>
    </div>
  );
};
