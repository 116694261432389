import {TimelineElement} from '@/components/player-v2/types';

export const getEndOfVideoTimelineElement = (
  element: React.ReactElement
): TimelineElement => {
  return {
    element,
    startSecond: -5,
    endSecond: Infinity,
  };
};

export const isActiveTimelineElement = ({
  duration,
  playedSeconds,
  timelineElement: {startSecond, endSecond},
}: {
  duration: number;
  playedSeconds: number;
  timelineElement: TimelineElement;
}) => {
  // normalized start and end second accounts for negative numbers
  const normalizedStartSecond =
    startSecond < 0 ? duration + startSecond : startSecond;
  const normalizedEndSecond = endSecond < 0 ? duration + endSecond : endSecond;

  return (
    playedSeconds >= normalizedStartSecond &&
    playedSeconds <= normalizedEndSecond
  );
};
