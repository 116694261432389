import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {PlayerControls} from '@/components/player-v2/PlayerControls';
import {ProjectVideoMetadata} from '@/components/player-v2/types';
import cx from 'classnames';

interface PlayerOverlayFooterProps {
  className?: string;
  metadata?: ProjectVideoMetadata;
}

export const PlayerOverlayFooter: React.FC<PlayerOverlayFooterProps> = ({
  className,
  metadata,
}) => {
  const {hasStarted} = usePlayer();

  if (!hasStarted && metadata && metadata.title && metadata.subtitle) {
    return (
      <div
        className={cx(
          'text-2xl sm:text-3xl uppercase tracking-widest text-white md:text-5xl py-16 px-10 font-sans',
          className
        )}
      >
        <div className="justify-end w-full flex gap-x-8">
          <span className="font-semibold">{metadata.title}</span>
          <span className="font-extralight">{metadata.subtitle}</span>
        </div>
      </div>
    );
  } else {
    return <PlayerControls className={className} />;
  }
};
