import cx from 'classnames';
import {
  useInternalPlayerConfig,
  usePlayer,
} from '@/components/player-v2/PlayerProvider';
import {PlayerOverlayCenter} from '@/components/player-v2/PlayerOverlayCenter';
import {PlayerOverlayFooter} from '@/components/player-v2/PlayerOverlayFooter';
import {PlayerOverlayTopBar} from '@/components/player-v2/PlayerOverlayTopBar';
import {
  ProjectVideoMetadata,
  TimelineElement,
} from '@/components/player-v2/types';

interface BasicPlayerOverlayProps {
  name?: string;
  className?: string;
  metadata?: ProjectVideoMetadata;
  timelineElements?: TimelineElement[];
}

export const BasicPlayerOverlay: React.FC<BasicPlayerOverlayProps> = ({
  name,
  className,
  metadata,
  timelineElements,
}) => {
  const {hasControls, hasEnded, hasStarted, isInteractingWithControls} =
    usePlayer();
  const {onInteractionWithControls} = useInternalPlayerConfig();

  const isOverlayVisible =
    hasControls && (!hasStarted || isInteractingWithControls || hasEnded);
  const hideableClassName = cx(
    'transition-all duration-300',
    {
      'opacity-0': !isOverlayVisible,
    },
    className
  );

  return (
    <div
      className={cx('flex flex-col font-rules', hideableClassName)}
      onMouseMove={onInteractionWithControls}
    >
      <PlayerOverlayTopBar />

      <PlayerOverlayCenter
        className={'flex-grow'}
        timelineElements={timelineElements}
      />

      <PlayerOverlayFooter metadata={metadata} />
    </div>
  );
};
