import {createContext, useContext, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {useRouter} from 'next/router';
import {
  BackButtonBehavior,
  ControlsConfig,
  PlayerVideoMetadata,
} from '@/components/player-v2/types';
import {useSingleTimeout} from '@/utils/hooks/useSingleTimeout';
import {emitSeekEvent} from '@/components/player-v2/Emitter';
import {useHasPreviousPath} from '@/components/PreviousPathProvider';
import {usePlayerVolume} from '@/components/player-v2/PlayerVolumeProvider';
import {useTracker} from '@/components/analytics/TrackerProvider';
import {usePlaybackVector} from '@/components/player-v2/usePlaybackVector';

export type PlayerConfig = {
  // Determines how the back button should behave on click
  backButtonBehavior?: BackButtonBehavior;

  // Configuration for the controls. Used for disabling certain controls or controls altogether.
  controls?: ControlsConfig;

  // Externally controls whether or not the player is muted.
  isMuted?: boolean;

  // Externally controls whether or not the player is playing.
  isPlaying?: boolean;

  // Callback called when the video ends.
  onEnded?: () => void;

  // Boolean determining whether or not the video should play in a loop.
  shouldLoop?: boolean;

  volume?: number;

  // When the user seeks to a new time we may want to redirect them (for the branching experience)
  onSeekRedirect?: (seconds: number) => number;
  onSeek?: (seconds: number) => void;

  onBuffer?: () => void;
  onBufferEnd?: () => void;
};

type PlayerStateContextValue = {
  playerRef: React.MutableRefObject<ReactPlayer | null>;
  isReady: boolean;
  isPlaying: boolean;
  isMuted: boolean;
  isInteractingWithControls: boolean;
  hasControls: boolean;
  disabledControls: string[];
  duration: number | null;
  hasEnded: boolean;
  hasStarted: boolean;
  buffering: boolean;
  id?: string;

  mute: () => void;
  seekTo: (seconds: number, shouldPause?: boolean) => void;
  togglePlay: () => void;
  toggleMute: () => void;
  toggleControls: (enabled: boolean) => void;
  setDisabledControls: (disabledControls: string[]) => void;
  play: () => void;
  pause: () => void;
};

const PlayerStateContext = createContext<PlayerStateContextValue>({
  playerRef: {current: null},
  isReady: false,
  isPlaying: false,
  isMuted: false,
  hasControls: true,
  hasEnded: false,
  hasStarted: false,
  disabledControls: [],
  isInteractingWithControls: false,
  duration: null,
  buffering: false,
  id: '',

  mute: () => {},
  seekTo: () => {},
  play: () => {},
  pause: () => {},
  setDisabledControls: (_controls) => {},
  togglePlay: () => {},
  toggleMute: () => {},
  toggleControls: (_hasControls) => {},
});

type PlayerConfigContext = {
  backButtonBehavior?: BackButtonBehavior;
  shouldLoop?: boolean;
  disableFullScreen?: boolean;
  onInteractionWithControls: () => void;
  onBackButtonClick: () => void;
  onReady: (reactPlayer: ReactPlayer) => void;
  onPause: () => void;
  onEnded: () => void;
  onStart: () => void;
  onDuration: (duration: number) => void;
  onBuffer: () => void;
  onBufferEnd: () => void;
};

const PlayerConfigContext = createContext<PlayerConfigContext>({
  onInteractionWithControls: () => {},
  onBackButtonClick: () => {},
  onReady: () => {},
  onPause: () => {},
  onEnded: () => {},
  onStart: () => {},
  onDuration: () => {},
  onBuffer: () => {},
  onBufferEnd: () => {},
});

export const usePlayer = () => {
  return useContext(PlayerStateContext);
};

export const useInternalPlayerConfig = () => {
  return useContext(PlayerConfigContext);
};

type PlayerProviderProps = {
  id?: string;
  children: React.ReactNode;
  backButtonBehavior?: BackButtonBehavior;
  isInitiallyPlaying: boolean;
  isInitiallyMuted: boolean;
  controls?: ControlsConfig;
  metadata: PlayerVideoMetadata;
  onEnded?: () => void;
  onSeek?: (seconds: number) => void;
  shouldLoop?: boolean;
  onSeekRedirect?: (seconds: number) => number;
};

/**
 * Wrapper component used for initializing and providing access to the player state and methods.
 * This component should wrap every full screen player.
 */
export const PlayerProvider = (props: PlayerProviderProps) => {
  const reactPlayerRef = useRef<ReactPlayer | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(props.isInitiallyPlaying);
  const [isMuted, setIsMuted] = useState(props.isInitiallyMuted);
  const [duration, setDuration] = useState<number | null>(null);
  const [buffering, setBuffering] = useState(false);
  const [disabledControls, setDisabledControls] = useState<string[]>(
    props.controls?.disabledControls ?? []
  );
  const [hasEnded, setHasEnded] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [hasControls, setHasControls] = useState(
    props.controls?.hasControls ?? true
  );
  const [isInteractingWithControls, setIsInteractingWithControls] =
    useState(false);
  const [setControlsInteractionTimeout] = useSingleTimeout();
  const {volume} = usePlayerVolume();

  const hasPreviousPath = useHasPreviousPath();
  const router = useRouter();

  const tracker = useTracker();

  const {recordPlaybackVector} = usePlaybackVector(
    reactPlayerRef.current,
    props.metadata
  );

  const [durationCheckAttempts, setDurationCheckAttempts] = useState(0);
  const MAX_DURATION_CHECK_ATTEMPTS = 3;
  const MIN_VALID_DURATION = 10;

  /**
   * Setter functions exposed by the context.
   */
  const play = () => {
    setIsPlaying(true);
  };

  const pause = () => {
    setIsPlaying(false);
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };
  const mute = () => {
    setIsMuted(true);
  };

  const toggleControls = (enabled: boolean) => {
    setHasControls(enabled);
  };

  /**
   * Method for maintaining whether or not the user has been interacting with the controls.
   * Using a timeout of a few seconds, if the user hasn't interacted with the controls then we can safely
   * set the value to false.
   */
  const onInteractionWithControls = () => {
    if (!isInteractingWithControls) {
      setIsInteractingWithControls(true);
    }

    setControlsInteractionTimeout(() => {
      setIsInteractingWithControls(false);
    }, 3000);
  };

  const onBuffer = () => {
    setBuffering(true);
  };

  const onBufferEnd = () => {
    setBuffering(false);
  };

  const onReady = (reactPlayer: ReactPlayer) => {
    setIsReady(true);
    reactPlayerRef.current = reactPlayer;
    (window as any).reactPlayer = reactPlayer;
  };

  const onStart = () => {
    setHasStarted(true);
  };

  const onDuration = (duration: number) => {
    if (
      duration < MIN_VALID_DURATION &&
      durationCheckAttempts < MAX_DURATION_CHECK_ATTEMPTS
    ) {
      // try to get duration again after a short delay
      setDurationCheckAttempts((prev) => prev + 1);
      setTimeout(() => {
        const currentDuration = reactPlayerRef.current?.getDuration();
        if (currentDuration && currentDuration > MIN_VALID_DURATION) {
          setDuration(Math.round(currentDuration));
        }
      }, 1000);
    } else {
      setDuration(Math.round(duration));
    }
  };

  const onPause = () => {
    setIsPlaying(false);
    onInteractionWithControls();
  };

  const onEnded = () => {
    setIsPlaying(false);
    setHasEnded(true);
    props.onEnded?.();
  };

  // TODO: Consider whether this should be placed elsewhere
  const onBackButtonClick = () => {
    if (props.backButtonBehavior?.type === 'none') {
      return;
    }
    recordPlaybackVector({
      type: 'back',
    });

    if (props.backButtonBehavior?.type === 'click') {
      return props.backButtonBehavior?.onClick();
    }

    if (hasPreviousPath) {
      tracker.capture('player.back-button.clicked', {
        player: props.id,
      });
      router.back();
    } else {
      const url = props.backButtonBehavior?.fallbackHref ?? '/';
      tracker.capture('player.back-button.clicked', {
        player: props.id,
        url: url,
      });
      router.push(url);
    }
  };

  const seekTo = (seconds: number, shouldPause?: boolean) => {
    let seekTarget = seconds;
    if (props.onSeekRedirect) {
      seekTarget = props.onSeekRedirect?.(seconds);
    }
    if (seekTarget < 1) {
      seekTarget = 0;
    }
    reactPlayerRef.current?.seekTo(seekTarget);

    if (shouldPause) {
      pause();
    } else {
      play();
    }
    props.onSeek?.(seekTarget);
    emitSeekEvent(seekTarget);
    onInteractionWithControls();
  };

  const context = {
    /* state */
    playerRef: reactPlayerRef,
    isReady,
    isPlaying,
    isMuted,
    isInteractingWithControls,
    volume,
    duration,
    disabledControls,
    hasControls,
    hasEnded,
    hasStarted,
    buffering,
    id: props.id,

    /* methods */
    play,
    pause,
    togglePlay,
    toggleMute,
    mute,
    toggleControls,
    setDisabledControls,
    seekTo,
  };

  const config = {
    backButtonBehavior: props.backButtonBehavior,
    shouldLoop: props.shouldLoop,
    disableFullScreen: props.controls?.disabledControls?.includes('fullscreen'),
    onBackButtonClick,
    onInteractionWithControls,
    onReady,
    onDuration,
    onPause,
    onEnded,
    onStart,
    onBuffer,
    onBufferEnd,
  };

  return (
    <PlayerStateContext.Provider value={context}>
      <PlayerConfigContext.Provider value={config}>
        {props.children}
      </PlayerConfigContext.Provider>
    </PlayerStateContext.Provider>
  );
};
