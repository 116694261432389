import {EventEmitter} from 'events';
import {SoundcloudTimestampType} from '@/components/player-v2/types';
export const globalEmitter = new EventEmitter();

export const emitSeekEvent = (seconds: number) => {
  globalEmitter.emit('seek', seconds);
};

export const emitSoundcloudEvent = ({
  seconds,
  type,
}: {
  seconds: number;
  type: SoundcloudTimestampType;
}) => {
  globalEmitter.emit('soundcloud', seconds, type);
};
