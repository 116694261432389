import {useEffect, useRef} from 'react';

/**
 * A hook that wraps setTimeout and clearTimeout but only allows for one timeout to exist.
 * If another timeout is set while one is ongoing, the ongoing one will be cleared.
 *
 * This hook also handles clearing the timeout on unmount.
 */
export const useSingleTimeout = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const internalClearTimeout = () => {
    clearTimeout(timeoutRef.current);
  };

  const internalSetTimeout = (cb: () => void, timeout: number) => {
    internalClearTimeout();
    timeoutRef.current = setTimeout(cb, timeout);
  };

  // Ensures that the timeout is cleared when unmounted.
  useEffect(() => internalClearTimeout, []);

  return [internalSetTimeout, internalClearTimeout];
};
