import {usePlayer} from '@/components/player-v2/PlayerProvider';
import cx from 'classnames';
import {globalEmitter} from '@/components/player-v2/Emitter';
import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Scrub =
  | {
      isScrubbing: false;
    }
  | {
      isScrubbing: true;
      timestamp: number;
    };

interface ProgressBarProps {
  className?: string;
}

const calculateTimestamp = (
  mouseX: number,
  progressBarRef: RefObject<HTMLDivElement>,
  duration: number | null
) => {
  const progressBar = progressBarRef.current;
  if (!progressBar || !duration) {
    return null;
  }

  const {left: progressBarLeftX, width: progressBarWidth} =
    progressBar.getBoundingClientRect();
  const percentageX = (mouseX - progressBarLeftX) / progressBarWidth;
  return percentageX * duration;
};

const INNER_PROGRESS_BAR_CLASSNAME =
  'h-full bg-blue-500 w-full -left-full absolute';

export const ProgressBar: React.FC<ProgressBarProps> = ({className}) => {
  const {duration, pause, seekTo, isPlaying, playerRef} = usePlayer();
  const [scrub, setScrub] = useState<Scrub>({isScrubbing: false});
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const innerProgressBarRef = useRef<HTMLDivElement>(null);
  const progressAnimationFrameIdRef = useRef<number | null>();
  const pauseTimeoutIdRef = useRef<NodeJS.Timeout | null>();

  const clearPauseTimeout = () => {
    if (pauseTimeoutIdRef.current) {
      clearTimeout(pauseTimeoutIdRef.current);
      pauseTimeoutIdRef.current = null;
    }
  };

  const cancelProgressAnimation = () => {
    if (progressAnimationFrameIdRef.current) {
      cancelAnimationFrame(progressAnimationFrameIdRef.current);
      progressAnimationFrameIdRef.current = null;
    }
  };

  const onScrub = (mouseClientX: number) => {
    const timestamp = calculateTimestamp(
      mouseClientX,
      progressBarContainerRef,
      duration
    );

    if (timestamp) {
      setScrub({isScrubbing: true, timestamp});
    }
  };

  const handleMouseDown: MouseEventHandler = (event) => {
    cancelProgressAnimation();
    onScrub(event.clientX);
    pauseTimeoutIdRef.current = setTimeout(pause, 200);
  };

  const handleMouseUp = () => {
    clearPauseTimeout();

    if (scrub.isScrubbing) {
      seekTo(scrub.timestamp);
      startProgressAnimation();
      setScrub({
        isScrubbing: false,
      });
    }
  };

  const handleMouseMove: MouseEventHandler = (event) => {
    if (!scrub.isScrubbing) {
      return;
    }

    onScrub(event.clientX);
  };

  /**
   * Kicks off a loop for syncing the inner progress bar animation
   * with the current timestamp of the video.
   */
  const startProgressAnimation = useCallback(() => {
    cancelProgressAnimation();

    const syncInnerProgressBar = () => {
      if (!innerProgressBarRef.current || !playerRef?.current || !duration) {
        return;
      }

      const timestamp = playerRef?.current?.getCurrentTime();
      const width = (timestamp / duration) * 100;
      innerProgressBarRef.current.style.transform = `translateX(${width}%)`;
      progressAnimationFrameIdRef.current = requestAnimationFrame(
        startProgressAnimation
      );
    };

    syncInnerProgressBar();
  }, [duration]);

  /**
   * useEffect to update the progress if the player starts playing or stop the progress if the player stops playing.
   */
  useEffect(() => {
    if (isPlaying) {
      startProgressAnimation();
    } else {
      cancelProgressAnimation();
    }
  }, [isPlaying, startProgressAnimation]);

  /**
   * useEffect to ensure that we clear the pause timeout and cancel
   * the progress animation frame on unmount.
   */
  useEffect(() => {
    globalEmitter.on('seek', startProgressAnimation);

    return () => {
      globalEmitter.off('seek', startProgressAnimation);
      clearPauseTimeout();
      cancelProgressAnimation();
    };
  }, []);

  if (!duration) {
    return null;
  }

  return (
    <>
      <div className={cx('flex flex-col items-center', className)}>
        <div
          className="relative mb-2 h-2 w-full cursor-pointer overflow-hidden rounded bg-gray-700"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          ref={progressBarContainerRef}
        >
          {/* Inner progress bar when not scrubbing. We use a combination of refs and
           * animation frames to create a smooth progress bar.
           */}
          <div
            className={cx(INNER_PROGRESS_BAR_CLASSNAME, {
              hidden: scrub.isScrubbing,
            })}
            ref={innerProgressBarRef}
          />

          {/* Inner progress bar when scrubbing. We use scrub.timestamp to determine
           * the position of the inner progress bar.
           */}
          <div
            className={cx(INNER_PROGRESS_BAR_CLASSNAME, {
              hidden: !scrub.isScrubbing,
            })}
            style={
              scrub.isScrubbing
                ? {
                    transform: `translateX(${
                      (scrub.timestamp / duration) * 100
                    }%)`,
                  }
                : {}
            }
          />
        </div>
      </div>

      {/* Overlay for handling mouseUp and mouseMove events when scrubbing */}
      {scrub.isScrubbing ? (
        <div
          className="fixed inset-0"
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        ></div>
      ) : null}
    </>
  );
};
