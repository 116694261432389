import {useEffect, useRef} from 'react';
import {secondsToTimeString} from '@/utils/time';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';

export const PlayerControlsTimestamp = ({duration}: {duration: number}) => {
  const {playedSeconds: seconds} = usePlayerProgress();
  const secondsElementRef = useRef<HTMLDivElement>(null);

  const durationString = secondsToTimeString(duration);

  useEffect(() => {
    if (secondsElementRef.current) {
      secondsElementRef.current.innerText = `${secondsToTimeString(
        seconds
      )} / ${durationString}`;
    }
  }, [seconds]);

  return <div className="mt-1 px-1 text-sm" ref={secondsElementRef} />;
};
