import cx from 'classnames';
import {PlayIcon} from '@heroicons/react/20/solid';
import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {TimelineElement} from '@/components/player-v2/types';
import {noop} from '@/utils/shared';
import {isActiveTimelineElement} from '@/components/player-v2/TimelineElement/utils';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';
import {SpinnerIcon} from '../white-rabbit/minting/Icons';

const PlayerOverlayCenterIcon = () => {
  const {isReady} = usePlayer();

  return (
    <div className="pointer-events-none touch-none">
      {isReady ? (
        <PlayIcon className="h-14 w-14" />
      ) : (
        <svg
          className="h-10 w-10 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </div>
  );
};

type PlayerOverlayCenterProps = {
  className?: string;
  timelineElements?: TimelineElement[];
};

export const PlayerOverlayCenter: React.FC<PlayerOverlayCenterProps> = ({
  className,
  timelineElements,
}) => {
  const {duration, togglePlay, hasControls, hasStarted, buffering} =
    usePlayer();
  const {playedSeconds} = usePlayerProgress();
  const handleClick = () => (hasControls ? togglePlay() : noop());

  return (
    <div
      className={cx(
        'justify-center flex items-center relative overflow-x-hidden',
        className
      )}
      onClick={handleClick}
    >
      {buffering ? <SpinnerIcon className="h-14 w-14" /> : null}
      {hasStarted ? (
        <>
          <div className="absolute inset-0 w-screen">
            {timelineElements?.map((timelineElement) => {
              if (
                duration &&
                isActiveTimelineElement({
                  timelineElement,
                  playedSeconds,
                  duration,
                })
              ) {
                return timelineElement.element;
              }

              return null;
            })}
          </div>
        </>
      ) : (
        <>{hasControls ? <PlayerOverlayCenterIcon /> : null}</>
      )}
    </div>
  );
};
