/**
 * Helper method for converting seconds into hours, minutes, and seconds.
 */
const secondsToHoursMinutesSeconds = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = Math.floor(totalSeconds % 60);

  return {hours, minutes, seconds};
};

/**
 * Helper method for converting seconds to a string represention of the total hours, minutes, and seconds.
 * If there are no hours, then the hours are not shown.
 */
export const secondsToTimeString = (totalSeconds: number) => {
  const {hours, minutes, seconds} = secondsToHoursMinutesSeconds(totalSeconds);
  const timeComponents =
    hours === 0 ? [minutes, seconds] : [hours, minutes, seconds];

  return timeComponents
    .map((component) => (component < 10 ? `0${component}` : String(component)))
    .join(':');
};
