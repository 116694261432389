import cx from 'classnames';
import ReactPlayer from 'react-player';
import {PlayerUrl} from '@/components/player-v2/types';
import {usePlayerKeyboardShortcuts} from '@/components/player-v2/usePlayerKeyboardShortcuts';
import {
  useInternalPlayerConfig,
  usePlayer,
} from '@/components/player-v2/PlayerProvider';
import {usePlayerVolume} from '@/components/player-v2/PlayerVolumeProvider';
import React, {useRef, useEffect} from 'react';

export type BasePlayerProps = {
  className?: string;
  url: PlayerUrl;
  fullScreen?: boolean;
  isActive: boolean;
  canToggleFullScreen?: boolean;
  onTimeUpdate?: (currentTime: number) => void;
};

/**
 * The wrapper component around ReactPlayer. Every full screen player should leverage
 * this component underneath the hood.
 */
export const BasePlayer: React.FC<BasePlayerProps> = ({
  className,
  url,
  isActive,
  fullScreen = true,
  onTimeUpdate,
}) => {
  const player = usePlayer();
  const {volume} = usePlayerVolume();
  const config = useInternalPlayerConfig();

  const playerRef = useRef<ReactPlayer>(null);

  usePlayerKeyboardShortcuts(isActive, playerRef);

  // Which controls are shown on the video player
  const controlsList = `${
    config.disableFullScreen ? 'nofullscreen' : ''
  } nodownload`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        onTimeUpdate?.(currentTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [onTimeUpdate]);

  return (
    <div
      className={cx(
        fullScreen ? 'h-screen w-screen' : '',
        'relative',
        className
      )}
    >
      <ReactPlayer
        config={{
          file: {attributes: {controlsList: controlsList}},
        }}
        ref={playerRef}
        height="100%"
        loop={config.shouldLoop}
        muted={player.isMuted}
        onDuration={config.onDuration}
        onBuffer={config.onBuffer}
        onBufferEnd={config.onBufferEnd}
        onEnded={config.onEnded}
        onPause={config.onPause}
        onReady={config.onReady}
        onStart={config.onStart}
        playing={player.isPlaying}
        url={url}
        volume={volume}
        width="100%"
      />
    </div>
  );
};
