import {createContext, useContext, useState} from 'react';

type PlayerVolumeProviderProps = {
  children: React.ReactNode;
};

type PlayerVolumeContext = {
  volume: number;
  setVolume: (target: number) => void;
};

const PlayerVolumeContext = createContext<PlayerVolumeContext>({
  volume: 1,
  setVolume: () => {},
});

export const usePlayerVolume = () => {
  const context = useContext(PlayerVolumeContext);
  if (!context) {
    throw new Error(
      'usePlayerVolume must be used within a PlayerVolumeProvider'
    );
  }
  return context;
};

export const PlayerVolumeProvider = (props: PlayerVolumeProviderProps) => {
  const [volume, setVolume] = useState(() => {
    const initialVolume = localStorage.getItem('shibuya-volume');
    return initialVolume ? parseFloat(initialVolume) : 1;
  });

  const setVolumeWithLocalStorage = (target: number) => {
    setVolume(target);
    localStorage.setItem('shibuya-volume', target.toString());
  };

  return (
    <PlayerVolumeContext.Provider
      value={{volume, setVolume: setVolumeWithLocalStorage}}
    >
      {props.children}
    </PlayerVolumeContext.Provider>
  );
};
