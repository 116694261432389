import {useCallback, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import {useTracker} from '@/components/analytics/TrackerProvider';
import {PlayerVideoMetadata} from './types';

const usePlaybackVector = (
  player: ReactPlayer | null,
  metadata: PlayerVideoMetadata
) => {
  const playbackVectorRef = useRef<number[]>([]);
  const lastRecordedSecondRef = useRef(0);

  const tracker = useTracker();

  const shouldLogPlaybackVector = metadata.variant === 'main';

  /**
   *  Slice up the duration of the video so that
      each second of playback can be accounted for individually
   */
  const determinePlaybackVector = useCallback(() => {
    if (
      !player ||
      player.getDuration() === Infinity ||
      !shouldLogPlaybackVector
    )
      return;
    // Zero fill the playback vector to the length of the current video duration
    const length = Math.ceil(player.getDuration());

    const vector = new Array(length).fill(0);
    playbackVectorRef.current = vector;
  }, [player]);

  const updatePlaybackVector = useCallback(async () => {
    if (!player || !shouldLogPlaybackVector) return;

    const currentSecond = Math.floor(player.getCurrentTime());

    const currentPlaybackVector = playbackVectorRef.current;
    if (currentPlaybackVector[currentSecond] === 0) {
      currentPlaybackVector[currentSecond] = 1;
    } else if (lastRecordedSecondRef.current !== currentSecond) {
      currentPlaybackVector[currentSecond] += 1;
    }

    // Update the lastRecordedSecond value
    lastRecordedSecondRef.current = currentSecond;
  }, [player]);

  const recordPlaybackVector = useCallback(async ({type}) => {
    if (!shouldLogPlaybackVector) return;
    if (playbackVectorRef.current.length > 0) {
      tracker.capture('player.playback-vector', {
        vector: playbackVectorRef.current,
        projectId: metadata.projectId,
        videoType: metadata.variant,
      });
    }

    // Reset the playback vector on emptied
    if (type === 'emptied' && playbackVectorRef.current.length > 0) {
      playbackVectorRef.current = [];
    }
  }, []);

  useEffect(() => {
    const internalPlayer = player?.getInternalPlayer();
    if (!internalPlayer || !shouldLogPlaybackVector) return;

    internalPlayer.addEventListener('loadedmetadata', determinePlaybackVector);
    return () =>
      internalPlayer.removeEventListener(
        'loadedmetadata',
        determinePlaybackVector
      );
  }, [player, determinePlaybackVector]);

  useEffect(() => {
    const internalPlayer = player?.getInternalPlayer();
    if (!internalPlayer || !shouldLogPlaybackVector) return;

    internalPlayer.addEventListener('timeupdate', updatePlaybackVector);
    return () =>
      internalPlayer.removeEventListener('timeupdate', updatePlaybackVector);
  }, [player, updatePlaybackVector]);

  useEffect(() => {
    const internalPlayer = player?.getInternalPlayer();
    if (!internalPlayer || !shouldLogPlaybackVector) return;
    // Send beacon when page is navigated away from
    window.addEventListener('beforeunload', recordPlaybackVector, false);

    return () => {
      window.removeEventListener('unload', recordPlaybackVector);
    };
  }, [player, recordPlaybackVector]);

  return {
    playbackVectorRef: playbackVectorRef,
    recordPlaybackVector,
  };
};

export {usePlaybackVector};
