import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {usePlayer} from '@/components/player-v2/PlayerProvider';

type PlayerProgressContext = {
  playedSeconds: number;
  setSeconds: (target: number) => void;
};

type PlayerProgressProviderProps = {
  children: React.ReactNode;
  projectId: string;
  playerId: string;
  fraction: number;
};

const PlayerProgressContext = createContext<PlayerProgressContext>({
  playedSeconds: 0,
  setSeconds: () => {},
});

export const usePlayerProgress = () => {
  const context = useContext(PlayerProgressContext);
  if (!context) {
    throw new Error(
      'usePlayerProgress must be used within a PlayerProgressProvider'
    );
  }
  return context;
};

export const PlayerProgressProvider = (props: PlayerProgressProviderProps) => {
  const {isPlaying, playerRef, isReady} = usePlayer();

  const intervalIdRef = useRef<NodeJS.Timeout | null>();

  const getRoundedSeconds = () => {
    if (!playerRef?.current) {
      return 0;
    }
    return (
      Math.round(playerRef.current?.getCurrentTime() * props.fraction) /
      props.fraction
    );
  };

  const [seconds, setSeconds] = useState(getRoundedSeconds());

  const clearTsInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const setNewSeconds = (target: number) => {
    setSeconds(target);
  };

  useEffect(() => {
    if (!isReady || !isPlaying) {
      clearTsInterval();
    } else {
      const player = playerRef.current;
      intervalIdRef.current = setInterval(() => {
        if (player) {
          setSeconds(getRoundedSeconds());
        }
      }, 1000 / props.fraction);
    }

    return () => clearTsInterval();
  }, [isPlaying, isReady]);

  const progress = {
    playedSeconds: seconds,
    setSeconds: setNewSeconds,
  };

  return (
    <PlayerProgressContext.Provider value={progress}>
      {props.children}
    </PlayerProgressContext.Provider>
  );
};
